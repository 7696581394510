import React, { useContext } from "react";
import "../../assets/style/globalStyle.scss";
import {
  updateSocietyById,
  deleteSociety,
} from "../../services/societyService";

import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "../../context/GlobalContextProvider";
import ModalCard from "../../components/modalCard";

import ImagePicker from "../../components/imagePicker";

import { Formik } from "formik";
import * as Yup from "yup";

const UpdateSocietySchema = Yup.object().shape({
  updatedName: Yup.string().required("Veuillez entrer le nom de la société"),
  updatedPhoneNumber: Yup.string().matches(/^[0-9]+$/, "Caractères invalides"),
  updatedEmail: Yup.string()
    .email("Email invalide")
    .required("Veuillez entrer un email de contact"),
  updatedContactName: Yup.string().required(
    "Veuillez entrer le nom du contact de la société"
  ),
});

const UpdateSocietyPage = () => {
  const GoToModal = async ({ modalType, showModal }) => {
    dispatch({
      type: "show_modal",
      payload: showModal,
      modalType: modalType,
    });
  };

  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  if (!state) {
    return (
      <div className="container is-fullhd" style={{ width: "880px" }}>
        <h1>Pas de société </h1>
      </div>
    );
  }

  const { _id, logo, name, url, phoneNumber, email, contactName, description } =
    state.society;

  return (
    <div className="box">
      <Formik
        initialValues={{
          updatedName: name,
          updatedUrl: url,
          updatedPhoneNumber: phoneNumber,
          updatedEmail: email,
          updatedContactName: contactName,
          updatedDescription: description,
        }}
        validationSchema={UpdateSocietySchema}
        onSubmit={(values) => {
          updateSocietyById({
            societyId: _id,
            name: values.updatedName,
            url: values.updatedUrl,
            phoneNumber: values.updatedPhoneNumber,
            email: values.updatedEmail,
            contactName: values.updatedContactName,
            description: values.updatedDescription,
          });
        }}
      >
        {({
          setFieldValue,
          handleChange,
          handleSubmit,
          values,
          errors,
          touched,
        }) => (
          <>
            <div className="field">
              <p className="label">
                Logo
                <ImagePicker
                  selectedId={_id}
                  imgLink={
                    logo
                      ? `https://res.cloudinary.com/ball-n-connect/image/v1/upload/society_image/${_id}.jpg`
                      : null
                  }
                  type="society"
                />
              </p>
            </div>
            <br />
            <div className="field">
              <label className="label">
                Nom de la société
                <input
                  className="input"
                  type="text"
                  placeholder={name}
                  value={values.updatedName}
                  onChange={(e) => setFieldValue("updatedName", e.target.value)}
                />
              </label>
            </div>
            {errors.updatedName && touched.updatedName && (
              <p style={{ color: "#cc0000" }}>{errors.updatedName}</p>
            )}
            <br />

            <div className="columns is-centered">
              <div className="column ">
                <div className="field">
                  <label className="label">
                    URL
                    <input
                      className="input"
                      type="url"
                      placeholder={url}
                      value={values.updatedUrl}
                      onChange={(e) =>
                        setFieldValue("updatedUrl", e.target.value)
                      }
                    />
                  </label>
                </div>
                {errors.updatedUrl && touched.updatedUrl && (
                  <p style={{ color: "#cc0000" }}>{errors.updatedUrl}</p>
                )}
              </div>

              <div className="column ">
                <div className="field">
                  <label className="label">
                    Numéro de téléphone
                    <input
                      className="input"
                      type="tel"
                      placeholder={phoneNumber}
                      value={values.updatedPhoneNumber}
                      onChange={(e) =>
                        setFieldValue("updatedPhoneNumber", e.target.value)
                      }
                    />
                  </label>
                </div>
                {errors.updatedPhoneNumber && touched.updatedPhoneNumber && (
                  <p style={{ color: "#cc0000" }}>
                    {errors.updatedPhoneNumber}
                  </p>
                )}
              </div>
              <br />
              <div className="column ">
                <div className="field">
                  <label className="label">
                    Email
                    <input
                      className="input"
                      type="email"
                      placeholder={email}
                      value={values.updatedEmail}
                      onChange={(e) =>
                        setFieldValue("updatedEmail", e.target.value)
                      }
                    />
                  </label>
                </div>
                {errors.updatedEmail && touched.updatedEmail && (
                  <p style={{ color: "#cc0000" }}>{errors.updatedEmail}</p>
                )}
              </div>
            </div>
            <br />

            <div className="field">
              <label className="label">
                Nom/prénom du contact
                <input
                  className="input"
                  type="text"
                  placeholder={contactName}
                  value={values.updatedContactName}
                  onChange={(e) =>
                    setFieldValue("updatedContactName", e.target.value)
                  }
                />
              </label>
            </div>
            {errors.updatedContactName && touched.updatedContactName && (
              <p style={{ color: "#cc0000" }}>{errors.updatedContactName}</p>
            )}
            <br />

            <div className="field">
              <label className="label">
                Description
                <textarea
                  className="textarea"
                  placeholder={description}
                  value={values.updatedDescription}
                  onChange={(e) =>
                    setFieldValue("updatedDescription", e.target.value)
                  }
                />
              </label>
            </div>
            <br />

            <div className="columns">
              <div className="column">
                <button
                  className="button is-primary is-outlined"
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  Enregistrer les modifications
                </button>
              </div>

              <div className="column">
                <button
                  className="button is-danger is-outlined"
                  onClick={() =>
                    GoToModal({ modalType: "delete", showModal: true })
                  }
                >
                  Supprimer la société
                </button>
              </div>
            </div>
          </>
        )}
      </Formik>
      <ModalCard
        isActiveClassName={state.showModal && state.modalType === "delete"}
        title="Supprimer"
        content="Voulez-vous supprimer cette société ?"
        validateClick={() => {
          deleteSociety({ societyId: _id });
          dispatch({
            type: "show_modal",
            payload: false,
            modalType: "",
          });
        }}
      />
    </div>
  );
};

export default UpdateSocietyPage;
